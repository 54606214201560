<template>
  <div>
    <!--begin::Mail Templates Details-->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card card-custom grid-stack-item-content">
          <div class="card-header">
            <div class="card-title w-100 justify-space-between">
              <h3
                v-if="!isBusy"
                class="card-title align-items-start flex-column"
              >
                <span class="card-label font-weight-bolder text-dark">
                  #{{ selectedMailTemplateData.id }} -
                  {{ selectedMailTemplateData.label }}
                </span>
                <span class="text-muted mt-3 font-weight-bold font-size-sm">
                  {{ selectedMailTemplateData.mailable_classname }}
                </span>
              </h3>
              <div v-else>
                <v-skeleton-loader type="heading" width="300px" class="mb-1" />
                <v-skeleton-loader type="text" width="300px" />
              </div>
              <button class="btn btn-primary" @click="saveMailTemplate">
                {{ $t("general.save") }}
              </button>
            </div>
          </div>
          <v-progress-linear v-if="isBusy" color="primary" indeterminate />
          <div class="card-body">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                {{ $t("mailTemplates.variables") }}
              </span>
            </h3>
            <div v-if="!isBusy">
              <span
                v-for="(variable, i) in selectedMailTemplateData.variables"
                :key="i"
                class="label label-primary label-inline font-weight-lighter mr-2 mb-2 text-white"
                style="cursor: pointer"
                @click="copyToClipboard(variable)"
              >
                {{ variable }}
              </span>
            </div>
            <div v-else>
              <v-skeleton-loader
                v-for="i in 8"
                :key="i"
                type="text"
                class="d-inline-block mr-1"
                width="80"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5 align-items-stretch">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card card-custom grid-stack-item-content">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">
                  {{ $t("mailTemplates.editTemplate") }}
                </span>
              </h3>
            </div>
          </div>
          <div class="card-body" style="min-height: 65vh">
            <div>
              <quill-editor
                ref="myTextEditor"
                v-model="selectedMailTemplateData.content"
                class="editor"
                :options="editorOption"
              />
              <div class="output code">
                <code class="hljs">{{ selectedMailTemplateData.content }}</code>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card card-custom grid-stack-item-content h-100">
          <div class="card-header">
            <div class="card-title w-100 justify-space-between">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">
                  {{ $t("mailTemplates.preview") }}
                </span>
              </h3>
              <button class="btn btn-primary" @click="loadPreview">
                {{ $t("general.reload") }}
              </button>
            </div>
          </div>
          <div class="card-body row">
            <iframe
              v-if="!isBusy"
              :srcdoc="livePreview"
              frameborder="0"
              class="w-100 mh-500"
            />
            <v-skeleton-loader v-else type="image" class="col-12" />
          </div>
        </div>
      </div>
    </div>
    <!--end::Mail Templates Details-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MailTemplates from "@/components/Admins/Settings/Email/MailTemplates/mailTemplates";

import hljs from "highlight.js";
import { quillEditor } from "vue-quill-editor";
import "highlight.js/styles/tomorrow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  name: "AdminsSettingsEmailMailTemplatesDetails",
  components: {
    quillEditor
  },
  data() {
    return {
      selectedMailTemplateData: {
        content: "",
        master_template: ""
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
            ["showHtml"]
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        }
      },
      isBusy: false,
      livePreview: ""
    };
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value;
    }
  },
  mounted() {
    this.loadMailTemplate();
  },
  methods: {
    loadMailTemplate() {
      this.isBusy = true;
      let mailTemplateId = this.$route.params.id;
      MailTemplates.get(mailTemplateId)
        .then(response => {
          this.selectedMailTemplateData = response.data.data;
          this.setContent();
          this.loadPreview();
          this.isBusy = false;
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$t("menu.adminSettings"),
              route: ""
            },
            {
              title: this.$t("menu.adminSettingsEmail"),
              route: "/admin/settings/email"
            },
            {
              title: this.$t("emailSettings.emailTemplates"),
              route: "/admin/settings/email"
            },
            {
              title: this.$t("general.overview"),
              route: "/admin/settings/email"
            },
            {
              title: this.selectedMailTemplateData.label,
              route:
                "/admin/settings/mail-templates/" +
                this.selectedMailTemplateData.id
            }
          ]);
        })
        .catch(e => {
          console.log(e);
          this.isBusy = false;
        });
    },
    setContent() {
      const html = this.selectedMailTemplateData.content;
      const delta = this.editor.clipboard.convert(html);
      this.editor.setContents(delta, "silent");
      this.content = html;
    },
    copyToClipboard(variable) {
      this.$copyText("{{$" + variable + "}}");
      this.$toast.fire({
        title: this.$t("general.copied"),
        icon: "success",
        timeOut: 1000,
        extendedTimeOut: 3000
      });
    },
    loadPreview() {
      let content = this.content;
      let masterTemplate = this.selectedMailTemplateData.master_template;
      this.$set(
        this,
        "livePreview",
        masterTemplate.replace("contentYEDI", content)
      );
    },
    saveMailTemplate() {
      this.isBusy = true;
      let templateId = this.$route.params.id;
      let data = this.selectedMailTemplateData;
      data.content = this.content;
      MailTemplates.update(templateId, data)
        .then(response => {
          this.selectedMailTemplateData = response.data.data;
          this.setContent();
          this.isBusy = false;
        })
        .catch(e => {
          console.log(e);
          this.isBusy = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.mh-500 {
  min-height: 500px;
}
</style>
